import React from 'react';
import Img from 'gatsby-image';

import { Link } from 'components/common/link/Link';
import faqBlockHook from 'hooks/faq-block/faq-block.hook';
import { sort } from 'utils/directus';

import s from './FaqBlock.scss';

export default (): JSX.Element => {
    const blocks = faqBlockHook();

    return (
        <div className={s.grid}>
            {sort(blocks).map(({ node }) => (
                <div className={s.block} key={`faq-block-${node.directusId}`}>
                    <Link to={node.link} className={s.block__link}>
                        <div className={s.block__image}>
                            <Img
                                fluid={node.image.localFile.childImageSharp.fluid}
                                alt={node.image_alt}
                                style={{ width: '100%' }}
                            />
                        </div>

                        <div className={s.block__body}>
                            <h3 className={s.block__body__title}>{node.title}</h3>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
};
