import React from 'react';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import Markdown from 'components/common/markdown/Markdown';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import mainBannerHook from 'hooks/main-banner/main-banner.hook';
import { useWindowWidth } from 'hooks/window-width';
import { getFieldBySlug } from 'utils/directus';
import { WIDTH_SM } from 'utils/pixel-widths';
import { Props, ResponsiveData } from './MainBanner.interface';

import s from './MainBanner.scss';

export default ({ slug }: Props): JSX.Element => {
    const isMobile = WIDTH_SM >= useWindowWidth();
    const { node } = getFieldBySlug(slug, mainBannerHook());
    const banner: ResponsiveData = isMobile ? {
        title: node.mobile_title,
        image: node.mobile_image,
        image_alt: node.mobile_image_alt,
    } : {
        title: node.desktop_title,
        image: node.desktop_image,
        image_alt: node.desktop_image_alt,
    };

    if (node.type === 'image-only') {
        return (
            <div className={s.imageBanner} style={{ backgroundColor: node.background_colour }}>
                <Wrapper>
                    <Img
                        fluid={banner.image.localFile.childImageSharp.fluid}
                        alt={banner.image_alt}
                        style={{
                            maxHeight: '260px',
                            width: `${node.desktop_image.width >= 1920 ? 1170 : node.desktop_image.width}px`,
                            maxWidth: '100vw',
                        }}
                    />
                </Wrapper>
            </div>
        );
    }

    if (node.type === 'image-only-full-width') {
        return (
            <div className={s.imageBanner} style={{ backgroundColor: node.background_colour }}>
                <Img
                    fluid={banner.image.localFile.childImageSharp.fluid}
                    alt={banner.image_alt}
                    style={{ width: '1170px' }}
                />
            </div>
        );
    }

    if (node.type === 'title-background') {
        return (
            <BackgroundImage fluid={banner.image.localFile.childImageSharp.fluid} className={s.titleBanner}>
                <div className={s.titleBanner__flex}>
                    <Wrapper>
                        <Markdown className={s.titleBanner__title} source={banner.title} container />
                    </Wrapper>
                </div>
            </BackgroundImage>
        );
    }

    throw new Error(`MainBanner type "${node.type}" does not exist.`);
};
